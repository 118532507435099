export default theme => ({
  root: {
    ...theme.flexColumnCenter
  },
  centered: {
    width: '30%',
    height: '30%',
    position: 'fixed', /* or absolute */
    top: '50%',
    left: '50%',
    marginTop: '-149px',
    marginLeft: '-178px',
  },
  centered2: {
    width: '350px',
    
    textAlign: 'center',
  },
  section: {
    ...theme.flexColumnCenter
  }
})
