import React from 'react'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import yoyuuURL from 'static/logo_box_name.svg'
import { makeStyles } from '@material-ui/core/styles'
import {
  ACCOUNT_PATH,
  LIST_PATH,
  LOGIN_PATH,
  SIGNUP_PATH
} from 'constants/paths'
import styles from './HomePage.styles'

const authWrapperUrl = 'https://github.com/mjrussell/redux-auth-wrapper'
const reactRouterUrl = 'https://github.com/ReactTraining/react-router'

const useStyles = makeStyles(styles)

function Home() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.centered}>
      <img 
      src={yoyuuURL}
      alt="Yoyuu"/>
      <br/>
      <br/>
      <div className={classes.centered2}>
      <br/>
      <Typography variant="h4">Coming Soon</Typography>
      </div>
      </div>
    </div>
  )
}

export default Home
