/**
 * NOTE: This file is ignored from git tracking. In a CI environment it is
 * generated by firebase-ci based on config in .firebaserc (see .gitlab-ci.yaml).
 * This is done so that environment specific settings can be applied.
 */

export const env = 'local'

// Config for firebase
export const firebase = {
  apiKey: 'AIzaSyDZa_4K0mDQ5Flwq9KfIVGTkp_7_NPLhxM',
  authDomain: 'yo-yuu.firebaseapp.com',
  databaseURL: 'https://yo-yuu.firebaseio.com',
  projectId: 'yo-yuu',
  storageBucket: 'yo-yuu.appspot.com'
}

// Config to override default reduxFirebase config in store/createStore
// which is not environment specific.
// For more details, visit http://react-redux-firebase.com/docs/api/enhancer.html
export const reduxFirebase = {
  enableLogging: false, // enable/disable Firebase Database Logging
  userProfile: 'users',
}

export const webRTCConfig = {
  defaultRoomName: 'TestRoom',
  defaultRoomPassword: 'ReplaceWithPassword',
  configUrl: 'https://api.simplewebrtc.com/config/guest/f0d4fa353a8f45fa474389e7',
}

export default {
  env,
  firebase,
  reduxFirebase
}
